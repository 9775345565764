import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '@/globals'
import afipRoutes from './afipModuleRoutes'
import ratesRoutes from './ratesRoutes'
// Layouts
import Layout1 from '@/layout/Layout2'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    base: '/',
    mode: 'history',
    routes: [
        {
            name: 'login',
            path: '/login',
            component: () => import('@/components/auth/LoginPage'),
            meta: {
                auth: false
            }
        },
        {
            name: 'logout',
            path: '/logout',
            component: () => import('@/components/auth/LogoutComponent'),
            meta: {
                auth: true
            }
        },
        {
            path: '/',
            component: Layout1,
            children: afipRoutes.concat(ratesRoutes, [{
                name: 'home',
                path: 'dashboard',
                component: () => import('@/components/Home'),
                meta: {
                    title: 'Inicio',
                    auth: true
                }
            },
            // **********************************************************************
            // HELPERS
            // **********************************************************************
            {
                name: 'debug',
                path: 'debug',
                component: () => import('@/components/DebugPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin',
                        appModules: 'aaaa'
                    }
                }
            },
            // **********************************************************************
            // HELPERS
            // **********************************************************************
            {
                name: '/',
                path: '/',
                component: () => import('@/components/Home'),
                meta: {
                    title: 'Home',
                    auth: true
                }
            },
            // **********************************************************************
            // HC
            // **********************************************************************
            {
                path: 'hc',
                name: 'hc',
                component: () => import('@/components/hc/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // HC
            // **********************************************************************
            {
                path: 'settings',
                name: 'settings',
                component: () => import('@/components/settings/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // ACCOUNT
            // **********************************************************************
            {
                name: 'account-settings',
                path: 'account/settings',
                component: () => import('@/components/account/SettingsPage'),
                meta: {
                    title: 'Configuración',
                    auth: true
                }
            },
            // **********************************************************************
            // FILES
            // **********************************************************************
            {
                name: 'files',
                path: 'files',
                component: () => import('@/components/files/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'files-categories',
                path: 'files/categories',
                component: () => import('@/components/categories/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'files-categories-create',
                path: 'files/categories/create',
                component: () => import('@/components/categories/FormPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // OFFICE
            // **********************************************************************
            {
                name: 'office',
                path: 'office',
                component: () => import('@/components/office/ShowPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // APPOINTMENTS
            // **********************************************************************
            {
                name: 'appointments-list-table',
                path: 'appointments/list',
                component: () => import('@/components/appointments/ListPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: ['admin', 'doctor']
                    }
                }
            },
            {
                name: 'appointments-list',
                path: 'appointments',
                component: () => import('@/components/appointments/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: ['admin', 'doctor']
                    }
                }
            },
            {
                name: 'appointments-calendar',
                path: 'appointments/calendar',
                component: () => import('@/components/appointments/CalendarPage'),
                meta: {
                    title: 'Calendario',
                    auth: {
                        roles: ['admin', 'doctor']
                    }
                }
            },
            {
                name: 'reports-appointments',
                path: 'reports/appointments',
                component: () => import('@/components/appointments/reports/AppointmentsPage'),
                meta: {
                    title: 'Calendario',
                    auth: {
                        roles: ['admin', 'doctor']
                    }
                }
            },
            {
                name: 'appointments-create',
                path: 'appointments/create',
                component: () => import('@/components/appointments/CreatePage'),
                props: true,
                meta: {
                    title: 'Calendario',
                    auth: {
                        roles: ['admin', 'doctor']
                    }
                }
            },
            // **********************************************************************
            // PATIENTS
            // **********************************************************************
            {
                name: 'patients-list',
                path: 'patients',
                component: () => import('@/components/patients/IndexPage'),
                meta: {
                    title: 'Pacientes',
                    auth: {
                        roles: ['admin', 'doctor']
                    }
                }
            },
            {
                name: 'patients-create',
                path: 'patients/create',
                component: () => import('@/components/patients/CreatePage'),
                meta: {
                    title: 'Crear',
                    auth: {
                        roles: ['admin', 'doctor']
                    }
                }
            },
            {
                name: 'patients-view',
                path: 'patients/:id',
                component: () => import('@/components/patients/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar pacientes',
                    auth: {
                        roles: ['admin', 'doctor']
                    }
                }
            },
            // **********************************************************************
            // companies
            // **********************************************************************
            {
                name: 'companies-list',
                path: 'companies',
                component: () => import('@/components/companies/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: ['admin']
                    }
                }
            },
            {
                name: 'companies-create',
                path: 'companies/create',
                component: () => import('@/components/companies/CreatePage'),
                meta: {
                    title: 'Crear',
                    auth: {
                        roles: ['admin']
                    }
                }
            },
            {
                name: 'companies-view',
                path: 'companies/:id',
                component: () => import('@/components/companies/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar pacientes',
                    auth: {
                        roles: ['admin']
                    }
                }
            },
            // **********************************************************************
            // MASS MAILING
            // **********************************************************************
            {
                name: 'mass-mailing-list',
                path: 'mass-mailing',
                component: () => import('@/components/mass-mailing/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'mass-mailing-create',
                path: 'mass-mailing/create',
                component: () => import('@/components/mass-mailing/CreatePage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'mass-mailing-edit',
                path: 'mass-mailing/:id/edit',
                component: () => import('@/components/mass-mailing/CreatePage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'mass-mailing-view',
                path: 'mass-mailing/:id',
                component: () => import('@/components/mass-mailing/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // STAFF
            // **********************************************************************
            {
                name: 'staff-list',
                path: 'staff',
                component: () => import('@/components/staff/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'staff-create',
                path: 'staff/create',
                component: () => import('@/components/staff/CreatePage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'staff-view',
                path: 'staff/:id',
                component: () => import('@/components/staff/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },

            // **********************************************************************
            // PRODUCTS
            // **********************************************************************
            {
                name: 'products-list',
                path: 'products',
                component: () => import('@/components/products/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'products-create',
                path: 'products/create',
                component: () => import('@/components/products/CreatePage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'products-edit',
                path: 'products/edit/:id',
                component: () => import('@/components/products/CreatePage'),
                props: true,
                meta: {
                    title: 'Edit Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'products-view',
                path: 'products/show/:id',
                component: () => import('@/components/products/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },

            // **********************************************************************
            // PRODUCTS CATEGORIES
            // **********************************************************************
            {
                name: 'products-categories-list',
                path: 'products/categories',
                component: () => import('@/components/products/categories/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'products-categories-create',
                path: 'products/categories/create',
                component: () => import('@/components/products/categories/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'products-categories-edit',
                path: 'products/categories/edit/:id',
                component: () => import('@/components/products/categories/CreatePage'),
                props: true,
                meta: {
                    title: 'Edit Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'products-categories-view',
                path: 'products/categories/show/:id',
                component: () => import('@/components/products/categories/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // PAYMENT METHODS
            // **********************************************************************
            {
                name: 'payment-methods-list',
                path: 'payment-methods',
                component: () => import('@/components/payment-methods/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'payment-methods-create',
                path: 'payment-methods/create',
                component: () => import('@/components/payment-methods/CreatePage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'payment-methods-edit',
                path: 'payment-methods/edit/:id',
                component: () => import('@/components/payment-methods/CreatePage'),
                props: true,
                meta: {
                    title: 'Edit Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'payment-methods-view',
                path: 'payment-methods/:id',
                component: () => import('@/components/payment-methods/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // APPOINTMENT REASONS
            // **********************************************************************
            {
                name: 'appointment-reasons-list',
                path: 'appointment-reasons',
                component: () => import('@/components/appointment-reasons/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'appointment-reasons-create',
                path: 'appointment-reasons/create',
                component: () => import('@/components/appointment-reasons/CreatePage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'appointment-reasons-edit',
                path: 'appointment-reasons/edit/:id',
                component: () => import('@/components/appointment-reasons/CreatePage'),
                props: true,
                meta: {
                    title: 'Edit Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'appointment-reasons-view',
                path: 'appointment-reasons/:id',
                component: () => import('@/components/appointment-reasons/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },

            // **********************************************************************
            // INSURANCES
            // **********************************************************************
            {
                name: 'insurances-list',
                path: 'insurances',
                component: () => import('@/components/insurances/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'insurances-create',
                path: 'insurances/create',
                component: () => import('@/components/insurances/CreatePage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'insurances-edit',
                path: 'insurances/edit/:id',
                component: () => import('@/components/insurances/CreatePage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'insurances-view',
                path: 'insurances/view/:id',
                component: () => import('@/components/insurances/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Staff',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // INSURANCES CONCILIATIONS
            // **********************************************************************
            {
                name: 'insurances-charges-list',
                path: 'insurances/charges',
                component: () => import('@/components/insurances/charges/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // SAMPLE TYPES
            // **********************************************************************
            {
                name: 'sample-published-results-list',
                path: 'samples/published-results',
                component: () => import('@/components/samples/publishedResults/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // SAMPLES
            // **********************************************************************
            {
                name: 'samples-list',
                path: 'samples',
                component: () => import('@/components/samples/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'samples-create',
                path: 'samples/create',
                component: () => import('@/components/samples/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear Muestra',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'samples-view',
                path: 'samples/:id',
                component: () => import('@/components/samples/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar ',
                    auth: true
                }
            },
            {
                name: 'samples-send',
                path: 'samples/:id/send',
                component: () => import('@/components/samples/SendPage'),
                props: true,
                meta: {
                    title: 'Mostrar ',
                    auth: true
                }
            },

            // **********************************************************************
            // SALES / Liquidations / Agreements
            // **********************************************************************
            {
                name: 'sales-liquidation-agreements',
                path: 'liquidations/agreements',
                component: () => import('@/components/liquidations/agreements/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-liquidation-agreements-create',
                path: 'liquidations/agreements/create',
                component: () => import('@/components/liquidations/agreements/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear Acuerdo',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-liquidation-agreements-edit',
                path: 'liquidations/agreements/edit/:resource_id',
                component: () => import('@/components/liquidations/agreements/CreatePage'),
                meta: {
                    title: 'Editar Template',
                    auth: {
                        roles: 'admin'
                    }
                }
            },

            // **********************************************************************
            // SALES / Liquidations
            // **********************************************************************
            {
                name: 'sales-liquidation-list',
                path: 'liquidations',
                component: () => import('@/components/liquidations/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-liquidation-create',
                path: 'liquidations/create',
                component: () => import('@/components/liquidations/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear Liquidación',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-liquidation-view',
                path: 'liquidations/:id',
                component: () => import('@/components/liquidations/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Liquidación',
                    auth: true
                }
            },
            {
                name: 'calendars-list',
                path: 'calendars',
                component: () => import('@/components/calendars/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'calendars-view',
                path: 'calendars/:id',
                component: () => import('@/components/calendars/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar agenda',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // SALES / Movements
            // **********************************************************************
            {
                name: 'sales-reports-movements',
                path: 'sales/reports/movements',
                component: () => import('@/components/sales/reports/MovementsPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // SALES / Movements by practice
            // **********************************************************************
            {
                name: 'sales-reports-movements-by-charge',
                path: 'sales/reports/movements/charge',
                component: () => import('@/components/sales/reports/MovementsByChargePage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // SALES / Payments
            // **********************************************************************
            {
                name: 'sales-payments-list',
                path: 'sales/payments',
                component: () => import('@/components/sales/payments/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-payments-create',
                path: 'sales/payments/create',
                component: () => import('@/components/sales/payments/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear Pago',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-payments-view',
                path: 'sales/payments/:id',
                component: () => import('@/components/sales/payments/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Pago',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // SALES / Charges
            // **********************************************************************
            {
                name: 'sales-charges-list',
                path: 'sales/charges',
                component: () => import('@/components/sales/charges/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-charges-create',
                path: 'sales/charges/create',
                component: () => import('@/components/sales/charges/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear Cobranza',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-charges-view',
                path: 'sales/charges/:id',
                component: () => import('@/components/sales/charges/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Cobranza',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            // **********************************************************************
            // SALES / Insurances Collections
            // **********************************************************************
            {
                name: 'insurances-collections-list',
                path: 'insurances/collections',
                component: () => import('@/components/insurances/collections/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'insurances-collections-create',
                path: 'insurances/collections/create',
                component: () => import('@/components/insurances/collections/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear Cobranza',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'insurances-collections-view',
                path: 'insurances/collections/:id',
                component: () => import('@/components/insurances/collections/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Cobranza',
                    auth: true
                }
            },
            {
                name: 'sales-collections-list',
                path: 'sales/collections',
                component: () => import('@/components/sales/collections/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-collections-create',
                path: 'sales/collections/create',
                component: () => import('@/components/sales/collections/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear Cobranza',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-collections-create-for-user-id',
                path: 'sales/collections/create/:for_user_id',
                component: () => import('@/components/sales/collections/CreatePage'),
                props: true,
                meta: {
                    title: 'Crear Cobranza',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'sales-collections-view',
                path: 'sales/collections/:id',
                component: () => import('@/components/sales/collections/ShowPage'),
                props: true,
                meta: {
                    title: 'Mostrar Cobranza',
                    auth: true
                }
            },
            /** EHR ADMIN */
            /** EHR TEMPLATES */
            {
                name: 'ehr-templates-list',
                path: 'ehr/templates',
                component: () => import('@/components/ehr/templates/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-template-create',
                path: 'ehr/templates/create',
                component: () => import('@/components/ehr/templates/FormPage'),
                meta: {
                    title: 'Nuevo Template',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-template-edit',
                path: 'ehr/templates/edit/:resource_id',
                component: () => import('@/components/ehr/templates/FormPage'),
                meta: {
                    title: 'Editar Template',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-template-view',
                path: 'ehr/templates/view/:resource_id',
                component: () => import('@/components/ehr/templates/ViewPage'),
                meta: {
                    title: 'Ver Template',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            /* EHR PRACTICES */
            {
                name: 'ehr-practices-list',
                path: 'ehr/practices',
                component: () => import('@/components/ehr/practices/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-practice-create',
                path: 'ehr/practices/create',
                component: () => import('@/components/ehr/practices/FormPage'),
                meta: {
                    title: 'Nueva práctica',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-practice-edit',
                path: 'ehr/practices/edit/:resource_id',
                component: () => import('@/components/ehr/practices/FormPage'),
                meta: {
                    title: 'Editar práctica',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-practice-view',
                path: 'ehr/practices/view/:resource_id',
                component: () => import('@/components/ehr/practices/ViewPage'),
                meta: {
                    title: 'Ver práctica',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            /* EHR OPTIONS */
            {
                name: 'ehr-options-list',
                path: 'ehr/options',
                component: () => import('@/components/ehr/options/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-option-create',
                path: 'ehr/options/create',
                component: () => import('@/components/ehr/options/FormPage'),
                meta: {
                    title: 'Nueva opción',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-option-edit',
                path: 'ehr/options/edit/:resource_id',
                component: () => import('@/components/ehr/options/FormPage'),
                meta: {
                    title: 'Editar opción',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-option-view',
                path: 'ehr/options/view/:resource_id',
                component: () => import('@/components/ehr/options/ViewPage'),
                meta: {
                    title: 'Ver opción',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            /* EHR SECTIONS */
            {
                name: 'ehr-sections-list',
                path: 'ehr/sections',
                component: () => import('@/components/ehr/sections/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-section-create',
                path: 'ehr/sections/create',
                component: () => import('@/components/ehr/sections/FormPage'),
                meta: {
                    title: 'Nueva sección',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-section-edit',
                path: 'ehr/sections/edit/:resource_id',
                component: () => import('@/components/ehr/sections/FormPage'),
                meta: {
                    title: 'Editar sección',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-section-view',
                path: 'ehr/sections/view/:resource_id',
                component: () => import('@/components/ehr/sections/ViewPage'),
                meta: {
                    title: 'Ver sección',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            /* EHR VARIABLES */
            {
                name: 'ehr-variables-list',
                path: 'ehr/variables',
                component: () => import('@/components/ehr/variables/IndexPage'),
                meta: {
                    title: 'Listado',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-variable-create',
                path: 'ehr/variables/create',
                component: () => import('@/components/ehr/variables/FormPage'),
                meta: {
                    title: 'Nueva variable',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-variable-edit',
                path: 'ehr/variables/edit/:resource_id',
                component: () => import('@/components/ehr/variables/FormPage'),
                meta: {
                    title: 'Editar variable',
                    auth: {
                        roles: 'admin'
                    }
                }
            },
            {
                name: 'ehr-variable-view',
                path: 'ehr/variables/view/:resource_id',
                component: () => import('@/components/ehr/variables/ViewPage'),
                meta: {
                    title: 'Ver variable',
                    auth: {
                        roles: 'admin'
                    }
                }
            },

            // **********************************************************************
            // ERRORS
            // **********************************************************************
            {
                name: 'not-authorized',
                path: 'not-authorized',
                component: () => import('@/components/errors/NotAuthorizedComponent'),
                meta: {
                    title: 'Error 403',
                    auth: true
                }
            },
            {
                name: 'not-found',
                path: '*',
                component: () => import('@/components/errors/NotFoundComponent'),
                meta: {
                    title: 'Error 404',
                    auth: true
                }
            },
            {
                name: 'internal-error',
                path: '*',
                component: () => import('@/components/errors/InternalErrorComponent'),
                meta: {
                    title: 'Error 500',
                    auth: true
                }
            }])
        }
    ]
})

router.afterEach(() => {
    // On small screens collapse sidenav
    if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
    }

    // Scroll to top of the page
    globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
    // Set loading state
    document.body.classList.add('app-loading')

    document.title = to.meta.title ? to.meta.title + ' - Appointments' : 'Appointments'
    if (!to.matched.length) {
        setTimeout(() => next({ name: 'not-found' }), 10)
    }

    // Add tiny timeout to finish page transition
    setTimeout(() => next(), 10)
})

router.beforeResolve((to, from, next) => {
    // If user-settings then restrict by own user
    // TODO: improve for any route
    if (to.meta.auth.owned && Vue.auth.user().id !== to.params.user_id) {
        setTimeout(() => next({ name: 'not-authorized' }), 10)
    }

    setTimeout(() => next(), 10)
})

export default router
