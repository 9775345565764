<template>
  <sidenav :orientation="orientation" :class="curClasses" style="background-color: #ffffff !important;">
  <!-- <sidenav :orientation="orientation" :class="curClasses"> -->

   <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" style="height: 66px;" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo demo  my-5">
        <b-avatar :size="35" style="background-color: #97dd28;" src="/isotipo.png" ></b-avatar>
      </span>
      <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">{{$auth.user().office.name.substring(0,15)}}</router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <!-- <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div> -->

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
        <sidenav-router-link icon="ion ion-md-people" to="/patients" :exact="true">Pacientes</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-business" to="/companies" :exact="true">Empresas</sidenav-router-link>
        <sidenav-router-link v-if="this.$auth.appModules('appointments')" icon="ion ion-ios-calendar" to="/appointments" :exact="true">Turnos </sidenav-router-link>

        <sidenav-menu v-if="this.$auth.appModules('laboratory')"
            :active="isMenuActive('/samples') || isMenuActive('/products/create')"
            :open="isMenuActive('/samples') || isMenuActive('/products/create')"
            icon="ion ion-ios-flask"
            :to="'/patients'">
                <template slot="link-text">Laboratorio</template>
                <sidenav-router-link to="/samples" :exact="true">Muestras / Solicitud de análisis</sidenav-router-link>
                <sidenav-router-link to="/samples/published-results" :exact="true">Resultados publicados</sidenav-router-link>
            </sidenav-menu>
        <sidenav-menu v-if="this.$auth.appModules('cash')"
            :open="isMenuActive('/sales') || isMenuActive('/rates')"
            icon="ion ion-md-card"
            :to="'/patients'">
                <template slot="link-text">Caja</template>
                <!-- <sidenav-router-link to="/dashboard" :exact="true">Dashboard</sidenav-router-link> -->
                <sidenav-router-link to="/sales/charges" :exact="true">Cargos</sidenav-router-link>
                <sidenav-router-link to="/sales/collections" :exact="true">Cobranzas</sidenav-router-link>
                <!-- <sidenav-router-link to="/insurances/collections" :exact="true">Cobranzas a OS</sidenav-router-link> -->
                <sidenav-router-link to="/sales/payments" :exact="true">Pagos</sidenav-router-link>
                <sidenav-router-link to="/rates" :exact="true">Tarifario </sidenav-router-link>
                <sidenav-menu
                    :open="isMenuActive('/sales/reports')"
                    icon="sidenav-icon ion-ios-pie"
                    :to="'/patients'">
                        <template slot="link-text">Reportes</template>
                        <sidenav-router-link :to="{name: 'sales-reports-movements'}" :exact="true">Movimientos</sidenav-router-link>
                        <!-- <sidenav-router-link :to="{name: 'sales-reports-movements-by-charge'}" :exact="true">Movimientos por cargo</sidenav-router-link> -->
                </sidenav-menu>
        </sidenav-menu>
        <!-- <sidenav-menu v-if="this.$auth.appModules('liquidations')" -->
        <!-- <sidenav-divider /> -->
        <sidenav-menu v-if="this.$auth.appModules('afip')"
            :active="isMenuActive('/afip')"
            :open="isMenuActive('/afip')"
            icon="fas fa-file-invoice-dollar"
            :to="'/patients'">
                <template slot="link-text">Facturación <strong>AFIP</strong></template>
                <sidenav-router-link to="/afip/invoices" :exact="true">Facturas</sidenav-router-link>
                <sidenav-router-link to="/afip/credit-note" :exact="true">Nota de crédito</sidenav-router-link>
                <sidenav-router-link to="/afip/debit-note" :exact="true">Nota de débito</sidenav-router-link>
                <sidenav-menu
                    v-if="$auth.roles('admin')"
                    :active="isMenuActive('/afip/companies')"
                    :open="isMenuActive('/afip/companies')">
                    <template slot="link-text">Configuración</template>
                    <sidenav-router-link to="/afip/companies" :exact="true">Empresas</sidenav-router-link>
                </sidenav-menu>
        </sidenav-menu>
        <!-- <sidenav-divider /> -->
        <sidenav-menu v-if="this.$auth.appModules('liquidations')"
            :active="isMenuActive('/liquidations')"
            :open="isMenuActive('/liquidations')"
            icon="fas fa-gavel"
            :to="'/patients'">
                <template slot="link-text">Liquidaciones</template>
                <sidenav-router-link to="/liquidations/create" :exact="true">Crear liquidación</sidenav-router-link>
                <sidenav-router-link to="/liquidations" :exact="true">Listado</sidenav-router-link>
                <sidenav-router-link to="/liquidations/agreements" :exact="true">Gestión de convenios</sidenav-router-link>
        </sidenav-menu>
        <sidenav-menu
            :open="isMenuActive('/appointments/reports')"
            icon="sidenav-icon ion-ios-pie">
                <template slot="link-text">Reportes</template>
                <sidenav-router-link :to="{name: 'reports-appointments'}" :exact="true">Turnos</sidenav-router-link>
                <!-- <sidenav-router-link :to="{name: 'sales-reports-movements-by-charge'}" :exact="true">Movimientos por cargo</sidenav-router-link> -->
        </sidenav-menu>
        <sidenav-menu
            v-if="$auth.roles('admin')"
            :active="isMenuActive('/office') || isMenuActive('/staff') || isMenuActive('/insurances') || isMenuActive('/products') || isMenuActive('/payment-methods') || isMenuActive('/calendars')"
            :open="isMenuActive('/office') || isMenuActive('/staff') || isMenuActive('/insurances') || isMenuActive('/products') || isMenuActive('/payment-methods') || isMenuActive('/calendars') || isMenuActive('/settings') || isMenuActive('/hc')"
            icon="sidenav-icon ion ion-md-switch">
            <template slot="link-text">Configuración</template>
            <sidenav-router-link to="/office" :exact="true">Consultorio</sidenav-router-link>
            <sidenav-router-link to="/staff" :exact="true">Staff</sidenav-router-link>
            <sidenav-router-link to="/calendars" :exact="true">Agendas</sidenav-router-link>
            <sidenav-router-link to="/appointment-reasons" :exact="true">Motivos de consulta</sidenav-router-link>
            <sidenav-menu
                v-if="$auth.roles('admin')"
                :active="isMenuActive('/products')"
                :open="isMenuActive('/products')">
                <template slot="link-text">Catálogo</template>
                <sidenav-router-link to="/products" :exact="true">Productos y servicios </sidenav-router-link>
                <sidenav-router-link to="/products/categories" :exact="true">Categorías </sidenav-router-link>
            </sidenav-menu>
            <sidenav-menu
            v-if="$auth.roles('admin')  && $auth.appModules('ehr')"
            :active="isMenuActive('/ehr/templates') ||  isMenuActive('/ehr/options') || isMenuActive('/ehr/sections') || isMenuActive('/ehr/variables')"
            :open="isMenuOpen('/ehr/templates') ||  isMenuOpen('/ehr/options') || isMenuOpen('/ehr/sections') || isMenuOpen('/ehr/variables')"
        >
            <template slot="link-text">Historia clínica</template>
            <sidenav-router-link icon="" :to="{ name: 'ehr-sections-list'}" :exact="false">Secciones</sidenav-router-link>
            <sidenav-router-link icon="" :to="{ name: 'ehr-templates-list'}" :exact="false">Templates</sidenav-router-link>
            <sidenav-router-link icon="" :to="{ name: 'ehr-options-list'}" :exact="false">Opciones</sidenav-router-link>
            <sidenav-router-link icon="" :to="{ name: 'ehr-variables-list'}" :exact="false">Variables</sidenav-router-link>
        </sidenav-menu>
            <sidenav-router-link to="/insurances" :exact="true">Financiadores </sidenav-router-link>
            <!-- <sidenav-router-link to="/hc" :exact="true">HC </sidenav-router-link> -->
            <sidenav-router-link to="/payment-methods" :exact="true">Formas de pago </sidenav-router-link>
            <sidenav-router-link to="/settings" :exact="true">Parámetros del sistema </sidenav-router-link>
        </sidenav-menu>
        <sidenav-menu
            v-if="$auth.roles('admin') && $auth.appModules('others')"
            :active="isMenuActive('/sales/reports') || isMenuActive('/dashboard') "
            :open="isMenuActive('/sales/reports') || isMenuActive('/dashboard') "
            icon="sidenav-icon ion ion-ios-flask">
            <template slot="link-text">labs</template>
            <sidenav-router-link to="/mass-mailing" :exact="true">Envios Masivos </sidenav-router-link>
        </sidenav-menu>
        <sidenav-divider></sidenav-divider>
        <sidenav-block v-if="$auth.user().email === 'ariel.s.alvarez@gmail.com' || $auth.user().email === 'silvinajaimes77@hotmail.com'">
            <div v-for="item in app_modules" :key="item.id">
                <!-- <button class="btn btn-link" @click="appModuleSwitch()" :class="{'text-success': $auth.appModules(item.name)}">{{item.name}} </button> -->
                <a href="javascript:;" @click="appModuleSwitch(item.id)" :class="{'text-success': $auth.appModules(item.name), 'text-danger': !$auth.appModules(item.name)}">{{item.description}}</a>
                <!-- <br>
                <span>{{item.description}}</span> -->
            </div>
        </sidenav-block>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'

export default {
    name: 'app-layout-sidenav',
    components: {
        /* eslint-disable vue/no-unused-components */
        Sidenav,
        SidenavLink,
        SidenavRouterLink,
        SidenavMenu,
        SidenavHeader,
        SidenavBlock,
        SidenavDivider
        /* eslint-enable vue/no-unused-components */
    },

    props: {
        orientation: {
            type: String,
            default: 'vertical'
        }
    },
    data () {
        return {
            loadingModules: false,
            app_modules: []
        }
    },

    computed: {
        resource () {
            return new Date()
        },
        curClasses () {
            console.log(this.layoutSidenavBg)
            let bg = this.layoutSidenavBg

            if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
                bg = bg
                    .replace(' sidenav-dark', '')
                    .replace(' sidenav-light', '')
                    .replace('-darker', '')
                    .replace('-dark', '')
            }

            return `bg-${bg} ` + (
                this.orientation !== 'horizontal'
                    ? 'layout-sidenav'
                    : 'layout-sidenav-horizontal container-p-x flex-grow-0'
            )
        }
    },
    mounted () {
        this.getAppModules()
    },
    methods: {
        appModuleSwitch (appModuleId) {
            this.axios({
                method: 'post',
                url: '/app/modules/switch',
                data: { app_module_id: appModuleId, office_id: this.$auth.user().office.id }
            })
                .then((response) => {
                    this.getAppModules()
                    this.$router.go()
                }).catch((error) => {
                    console.log(error)
                }).finally(() => {
                    // this.isFormSubmiting = false
                })
        },
        getAppModules: function () {
            this.loadingModules = true
            this.axios
                .get('/app/modules')
                .then(response => {
                    this.app_modules = response.data.data
                })
                .finally(response => {
                    this.loadingModules = false
                })
        },
        confirmRemove () {
            this.deleteResource().then(response => {
                this.fetchFiles({
                    parent_model: this.parent_model,
                    parent_id: this.parent_id
                })
                this.delete_modal = false
                this.$eventHub.$emit('notifySuccess', response.message)
            })
        },
        isMenuActive (url) {
            return this.$route.path.indexOf(url) === 0
        },

        isMenuOpen (url) {
            return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
        },

        toggleSidenav () {
            this.layoutHelpers.toggleCollapsed()
        }
    }
}
</script>
